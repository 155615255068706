import Swiper from 'swiper';
import { Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import './styles.scss';

Swiper.use([Pagination]);

function initSuggestionSwiper() {
  const swiperSuggestion = document.querySelector('[data-swiper-suggestion]');
  if (swiperSuggestion.length === 0) {
    return;
  }

  const swiper = new Swiper(swiperSuggestion, {
    slidesPerView: 'auto',
    freeMode: true,
    pagination: {
      el: '.swiper-pagination-suggestion',
      clickable: true,
      renderBullet: function (index, className) {
        return `<button class="${className}"><span class="sr-only">Zu Element ${index + 1}</span></button>`;
      },
    },
    breakpoints: {
      467: {
        slidesPerGroup: 2,
      },
      1023: {
        slidesPerGroup: 3,
      },
    },
    on: {
      init: function () {
        const paginationBulletsActive = document.querySelector(
          '.swiper-pagination-suggestion .swiper-pagination-bullet-active'
        );
        paginationBulletsActive?.setAttribute('aria-current', 'true');
      },
    },
  });

  swiper.on('slideChange', function () {
    const paginationBullets = document.querySelectorAll('.swiper-pagination-suggestion .swiper-pagination-bullet');
    for (let i = 0, len = paginationBullets.length; i < len; i++) {
      if (paginationBullets[i].classList.contains('swiper-pagination-bullet-active')) {
        paginationBullets[i].setAttribute('aria-current', 'true');
      } else {
        paginationBullets[i].removeAttribute('aria-current');
      }
    }
  });
}

export { initSuggestionSwiper };

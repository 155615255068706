import Swiper from 'swiper';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import './styles.scss';

Swiper.use([Navigation]);

function initLawyerSwiper() {
  const swiperLawyer = document.querySelector('[data-swiper-lawyer]');
  if (swiperLawyer.length === 0) {
    return;
  }

  new Swiper(swiperLawyer, {
    loop: true,
    freeMode: true,
    watchOverflow: true,
    slidesPerView: 'auto',
    navigation: {
      nextEl: '.swiper-button-next-lawyer',
      prevEl: '.swiper-button-prev-lawyer',
    },
  });
}

export { initLawyerSwiper };

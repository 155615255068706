import Swiper from 'swiper';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import './styles.scss';

Swiper.use([Navigation]);

function initCompanySwiper() {
  const swiperCompany = document.querySelector('[data-swiper-company]');
  if (swiperCompany.length === 0) {
    return;
  }

  new Swiper(swiperCompany, {
    loop: true,
    freeMode: true,
    watchOverflow: true,
    slidesPerView: 'auto',
    navigation: {
      nextEl: '.swiper-button-next-company',
      prevEl: '.swiper-button-prev-company',
    },
  });
}

export { initCompanySwiper };

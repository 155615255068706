import { initLawyerSwiper } from './swiper-lawyer';
import { initCompanySwiper } from './swiper-company';
import { initSuggestionSwiper } from './swiper-suggestion';
import './styles.scss';

document.addEventListener('DOMContentLoaded', function () {
  initSuggestionSwiper();
  initLawyerSwiper();
  initCompanySwiper();
});
